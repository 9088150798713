
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import defaultimage from '../../assets/DefaultImage.svg'
// import { ErrorMessage } from '../../views/Configurations/language'
import { SetListofCars, setProps, StoreLanguage } from '../Store/index'
import { GetCompareCars, RemoveCompareCars, GetAllCompareCars, StoreCompareCars } from '../../server/index'
import AddRound from '../../assets/AddFullRound.svg'
import EmptyScreen from '../Helper/EmptyScreen';
import './indexStyle.css'
import { NavLink, useParams, useNavigate } from 'react-router-dom';

import star from '../../assets/star.svg'
function Appcomparing(prop) {
    const dispatch = useDispatch()
    let ReminingData = useSelector(state => state.storeContent.ListofCars);
    const profiles = useSelector(state => state.storeContent.UserData);
    const [CarsData, SetcarsData] = useState(prop.data.carscompare)
    const [RawData, SetRawData] = useState(prop.data)
    const [ListofCarsLocal, SetListofCarsLocal] = useState([])
    const currencies = JSON.parse(localStorage.getItem(`Cur`))
    const [popUpData, setpopUpData] = useState({})
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });
    useEffect(() => {
        SetcarsData(prop.data.carscompare)
        SetRawData(prop.data)
    }, [prop]);
    const params = useParams()
    const navigate = useNavigate();
    const ClickOnCall = (prop) => {
        // dispatch(StoreLanguage.setProps(
        //     [prop.data.PopUpData.SuccessMessage.GetinTouch]
        // ))
        if (isMobileDevice()) {
            setpopUpData({
                ...prop.owner
                , Ismobile: true
            });
        } else {
            setpopUpData(prop.owner
            );
        }
    }
    const trigger = async () => {
        if (profiles && profiles?.id) {
            try {
                if (params?.Productid) {
                    await StoreCompareCars({
                        headers: {
                            Authorization: `Token ${profiles?.Authorization}`,
                        }
                    }, params?.Productid)
                }
                const { data } = await GetAllCompareCars({
                    headers: {
                        Authorization: `Token ${profiles?.Authorization}`,
                    }
                })
                let convertdata = data.map(a => ({ ...a, rate: a.rating.rate, button: { title: 'Call / meet', owner: a.owner } }))
                SetListofCarsLocal(convertdata.map(({ full_name, price, rate, km_driven, body_type, engine, fuel_type, max_power, button, ...rest }) => ({ full_name, price, rate, km_driven, body_type, engine, fuel_type, max_power, button, ...rest })));
            } catch (error) {
                if (error?.response?.status === 500) {
                    dispatch(StoreLanguage.setProps([{
                        Title: '500 server error',
                        SubTitle: '500 server error',
                        Styling: {
                            Icon: 'Error',
                        }
                    }]));
                } else {
                    let temp = []
                    for (let er in error?.response?.data) {
                        temp.push({
                            Title: er,
                            SubTitle: Array.isArray(error?.response?.data[er]) ? error?.response?.data[er][0] : error?.response?.data[er],
                            Styling: {
                                Icon: 'Error',
                            }
                        })
                    }
                    dispatch(StoreLanguage.setProps(temp))
                }
            }
        }
    }
    useEffect(() => {
        if (profiles?.id) {
            trigger()
        }
    }, [profiles]);
    useEffect(() => {
        if (!ListofCarsLocal.length) {
            navigate('/product-comparison');
        }
    }, [ListofCarsLocal.length, navigate]); 
    console.log(ListofCarsLocal, 'ListofCarsLocal')
    const RemoveItem = async (prop) => {
        SetListofCarsLocal(ListofCarsLocal.filter(obj => obj.id !== prop.id))
        try {
            await RemoveCompareCars({
                headers: {
                    Authorization: `Token ${profiles?.Authorization}`,
                }
            }, prop?.id)
            dispatch(setProps([RawData.PopUpData.ErrorMessage.RemovedProduct]))
        } catch (error) {
            if (error?.response?.status === 500) {
                dispatch(StoreLanguage.setProps([{
                    Title: '500 server error',
                    SubTitle: '500 server error',
                    Styling: {
                        Icon: 'Error',
                    }
                }]));
            } else {
                let temp = []
                for (let er in error?.response?.data) {
                    temp.push({
                        Title: er,
                        SubTitle: Array.isArray(error?.response?.data[er]) ? error?.response?.data[er][0] : error?.response?.data[er],
                        Styling: {
                            Icon: 'Error',
                        }
                    })
                }
                dispatch(StoreLanguage.setProps(temp))
            }
        }

    }
    const isMobileDevice = () => {
        return /Mobi|Android/i.test(navigator.userAgent);
    }
    const callPhoneNumber = (prop) => {
        window.location.href = `tel:${prop}`;
    }
    const close = (popup, number) => {
        if (popup) {
            callPhoneNumber(number);
            setpopUpData({})
        } else {
            setpopUpData({})
        }
    }
    return (
        <>
            {popUpData?.Authorization && <div className='w-full min-h-full h-auto z-[12] bg_blur fixed top-0'>
                <div className='w-full h-[100vh] flex justify-center items-center'>
                    <div className=' bg-[#3B3F49] w-full md:w-[50%] lg:w-[25%] rounded-lg'>
                        <div className='p-4'>
                            <div className='w-full flex justify-end'>
                                <div className="w-[30px] h-[30px] rounded-full text-xl cursor-pointer text-white bg-[#62656D]" onClick={() => close()}>
                                    <div className="flex items-center justify-center">x</div>
                                </div>
                            </div>
                            <div className='px-4'>
                                <div>
                                    <div className='flex items-start flex-col text-white py-5'>
                                        <div className='text-lg pb-1'>
                                            Thank you for your interest. You can contact {popUpData?.first_name} on this number {popUpData?.Authorization} for more details.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`w-full flex items-center pb-2 ${popUpData.Ismobile ? 'justify-between' : 'justify-center'}`}>
                                <div className="w-[90px] h-8 rounded-[5px] cursor-pointer flex justify-center items-center" onClick={() => close()} style={{ background: "linear-gradient(220.6deg, #ffbe41 60.41%, #ff9334 121.42%)" }}>
                                    <p className="text-md font-bold text-center uppercase text-[rgb(51,51,51)] center_aline">
                                        Close
                                    </p>
                                </div>
                                {popUpData.Ismobile && <div className="w-[90px] h-8 rounded-[5px] cursor-pointer flex justify-center items-center" onClick={() => close(popUpData.Ismobile, popUpData?.Authorization)} style={{ background: "linear-gradient(220.6deg, #ffbe41 60.41%, #ff9334 121.42%)" }}>
                                    <p className="text-md font-bold text-center uppercase text-[rgb(51,51,51)] center_aline">
                                        Call
                                    </p>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            <div className='min-h-full h-auto px-[5%] py-[2%] Def-font'>
                <div className='flex justify-between'>
                    <div className='pb-[30px]'>
                        <p className="text-[18px] md:text-[30px] lg:text-[34px] font-bold text-left text-white">{CarsData.MainTitle}({ListofCarsLocal?.length})</p>
                        <p className="text-[18px] md:text-[30px] text-2xl text-left text-white hidden md:block">
                            {ListofCarsLocal?.length > 0 &&
                                ListofCarsLocal?.map((a, i) => (
                                    <span key={i}>
                                        {a.full_name}
                                        {i !== ListofCarsLocal?.length - 1 && ' vs '}
                                    </span>
                                ))}
                        </p>
                    </div>
                    <div>
                        <NavLink to={'/product-detail'}>
                            <div className='border border-[#72787C] h-[58px] w-[163px] rounded-lg flex justify-center items-center bg-[#ffffff26]'>
                                <img src={AddRound} className="h-[24px] w-[24px]" alt="logo" />
                                <span className='text-[#BBBBBB] text-md pl-3'>Add more</span>
                            </div>
                        </NavLink>
                    </div>
                </div>
                {ListofCarsLocal?.length > 0 &&
                    <div className="car-comparison">
                        <div className="car-list relative pb-5">

                            <div className="absolute w-full top-0 z-8">
                                <div className='h-[95px] md:h-[240px] lg:h-[280px] w-[540px]'>

                                </div>
                                {CarsData.titles.map((car, i) => (
                                    <div className='w-full'>
                                        {i !== 0 && <div className="border-b-2 border-[#72787C] w-[99%] h-[3px]" />}
                                        <div className='py-[18px] md:py-[20px] lg:py-[27px]'>
                                            <span className='my-[27px]'>
                                                <p className="text-sm md:text-md lg:text-lg text-left text-white pl-4">{car}</p>
                                            </span>
                                        </div>
                                    </div>

                                ))}
                            </div>
                            <div className='ml-[150px] md:ml-[200px] lg:ml-[237px] flex'>
                                {ListofCarsLocal.map((car, index) => (
                                    <div className='w-[280px] md:w-[350px] lg:w-[400px] pt-4 md:pt-0 lg:pt-0 flex flex-col items-center md:items-stretch lg:items-stretch' key={car.id}>
                                        <div className='w-[30%] md:[80%] lg:w-[90%] h-[80px] md:h-[240px] lg:h-[280px] pb-[30px] mx-[5%] relative z-8'>
                                            <div className='w-[20px] h-[20px] bg-white absolute right-[10px] top-[-5px] md:top-[10px] lg:top-[10px] rounded-full flex items-center justify-center cursor-pointer z-[10]' onClick={() => RemoveItem(car)}>x</div>
                                            {/* <img src={car.image.length ? car.image : defaultimage} className="" alt="logo" /> */}
                                            {car?.image && /\.(mp4|webm|ogg)$/i.test(car.image) ? (
                                                <video className="w-full h-full rounded-xl object-cover" controls>
                                                    <source src={car.image} type="video/mp4" />
                                                    <source src={car.image} type="video/webm" />
                                                    <source src={car.image} type="video/ogg" />
                                                    Your browser does not support the video tag.
                                                </video>
                                            ) : (
                                                <img
                                                    className="w-[72px] md:w-full lg:w-full h-[62px]  md:h-full  lg:h-full rounded-xl"
                                                    src={car.image.length ? car.image : defaultimage}
                                                    alt=""
                                                />
                                            )}
                                        </div>
                                        <div className="">
                                            <div
                                                className="rounded-[20px] bg-[#ffffff26] border border-white w-[230px] md:w-[90%] lg:w-[90%] mx-[2%] md:mx-[5%] lg:mx-[5%]"
                                                style={{ boxShadow: "0px 2px 34px 0 rgba(0,0,0,0.18)" }}
                                            >
                                                {
                                                    Object.entries(car).map(([key, value]) => (
                                                        (key !== 'image' && key !== 'id' && key !== 'review' && key !== 'rating' && key !== 'min_price' && key !== 'max_price' && key !== 'owner' && key !== 'mileage' && key !== 'fuel_consumption' && key !== 'starlogo' && key !== 'power') && (
                                                            <div className=''>
                                                                {(key !== 'title' && key !== 'button') && <div className="w-full h-[3px]" />}
                                                                {key !== 'button' && <div className='py-[18px] md:py-[20px] lg:py-[27px]' key={key}>
                                                                    {key !== 'rate' && <span className='flex justify-center'>
                                                                        {/* <p className="text-sm md:text-md lg:text-lg text-left text-white">{key === 'price' ? value ? value.toLocaleString(currencies?.format, { style: 'currency', currency: currencies.key }) : '--' : value ? value : '--'}</p> */}
                                                                        <p className="text-sm md:text-md lg:text-lg text-left text-white">{key === 'price' ? value ? formatter.format(value) : '--' : value ? value : '--'}</p>
                                                                    </span>}
                                                                    {key === 'rate' && <span className='flex justify-center'>
                                                                        <p className="text-sm md:text-md lg:text-lg text-left text-white flex">{<img className="pr-2" src={star} alt="" />} {value}</p>
                                                                    </span>}
                                                                </div>}
                                                                {key === 'button' && <div className='py-[18px] md:py-[20px] lg:py-[27px] flex justify-center' key={key}>
                                                                    <div className="w-[156px] h-[34px] cursor-pointer" onClick={() => ClickOnCall(value)}>
                                                                        <div className="w-[156px] h-[34px] rounded-[5px] flex items-center justify-center" style={{ background: "linear-gradient(220.6deg, #ffbe41 60.41%, #ff9334 121.42%)" }} >
                                                                            <p className="text-sm md:text-base lg:text-base font-bold text-center uppercase text-[#333] flex justify-center">
                                                                                {value.title}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>}
                                                            </div>
                                                        )
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>
                }
            </div>
            {ListofCarsLocal?.length === 0 &&
                <div className='w-full h-[80vh] flex justify-center items-center'>
                    <EmptyScreen />
                </div>
            }
            <div>
                {CarsData.subbanner}
            </div>
        </>
    );
}

export default Appcomparing;

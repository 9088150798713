
import React, { useState, useEffect, useRef } from 'react';
import {
    FacebookShareButton,
    TwitterShareButton,
    PinterestShareButton,
    WhatsappShareButton,
    RedditShareButton,
    FacebookIcon,
    XIcon,
    PinterestIcon,
    WhatsappIcon,
    RedditIcon,
    EmailIcon,
    EmailShareButton,
    // InstagramShareButton,
    // InstagramIcon
} from 'react-share';
import ImgSlider from '../ImgSlider/ImgSlider'
import Cards from '../Helper/cards'
import Rinput from '../Helper/RInput';
import logo from '../../assets/logo.svg'
import copy from '../../assets/copy.svg'
import stars from '../../assets/star.svg'
import line from '../../assets/Line.svg'
import DefaultImage from '../../assets/DefaultImage.svg'
import editPen from '../../assets/EditPen_BG_w.svg'
import share from '../../assets/Share.svg'
import { NavLink } from 'react-router-dom';
// import { ErrorMessage } from '../../views/Configurations/language'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TickMark from '../../assets/GreenTickMark.svg'
import MobileSlider from '../ImgSlider/ImageSliderMobile'
import DownAerrow from '../../assets/Down_Aerrow.svg'
import { StoreLanguage, setProps, SetRatting, PatchPostedcar, SetAddNewPost, SetTabData } from '../Store/index';
// import { GetCar, GetUserAllCars, ReviewOfVehicle, UserCars } from '../../server/index';
import { GetCar, GetSimilarCar, UserCars, GetbackRequest, ChnageStatus } from '../../server/index';
function Appcomparing(prop) {
    let params = useParams()
    const dispatch = useDispatch()
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });
    const [productdata, setproductdata] = useState([])
    const [Arrayfields, setArrayfields] = useState([])
    const [popUpData, setpopUpData] = useState({})
    const ratting = useSelector(state => state.storeContent.Ratting);
    const profiles = useSelector(state => state.storeContent.UserData);
    const DataStatus = useSelector(state => state.storeContent.DataStatus);
    const StatusLib = [{ name: 'cancel' }, { name: 'Sold', tag: 'sold' }, { name: 'For Sale', tag: 'forsale' }]
    const [HideReasion, setHideReasion] = useState(false)
    const [tagData, settagData] = useState('')
    const [storeReasion, setstoreReasion] = useState('')
    const [ReasionStatus, setReasionStatus] = useState(false)
    const [SelectedReasion, setSelectedReasion] = useState('Remove Listing')
    // const Requiretabdata = useSelector(state => state.storeContent.Tabdata);
    // const AddNewPost = useSelector(state => state.storeContent.AddNewPost);
    const [Tabdata, SetTabdata] = useState('Overview')
    const [TabdataMobile, SetTabdataMobile] = useState('Overview')
    const [Rawdata, SetRawdata] = useState({})
    const [Userdata, SetUserdata] = useState([])
    // const [ReviewData, SetReviewData] = useState({})
    const [showmore, Setshowmore] = useState(false)
    const currencies = JSON.parse(localStorage.getItem(`Cur`))
    // useEffect(() => {
    //     if (profiles?.Authorization) {
    //         GettingBanners(profiles)
    //     }
    // }, [AddNewPost, ratting]);
    useEffect(() => {
        if (profiles?.Authorization) {
            GettingBanners(profiles)
        }
    }, [ratting, params, DataStatus]);
    useEffect(() => {
        if (profiles?.Authorization) {
            Toggle(profiles, Rawdata.owner);
        }
    }, [profiles]);
    const [tabs, Settabs] = useState([])
    const [Mobiletabs, SetMobiletabs] = useState([])
    const [Features, SetFeatures] = useState([])
    // const [specials, setspecials] = useState([])
    // const [inFeatures, setinFeatures] = useState([])
    // const [SpecificationLocal, setSpecificationLocal] = useState([])
    useEffect(() => {
        Settabs(prop.data.productTab.map(a => a.Title === 'Overview' ? {
            Title: 'Overview',
            Unable: true,
            ...a
        } : a))
        SetMobiletabs(prop.data.productTab)
        setproductdata(prop.data.productdata)
        GettingBanners(profiles)
    }, [prop, DataStatus]);
    useEffect(() => {
        dispatch(SetTabData(localStorage.getItem(`TabData`)))
    }, []);
    const Toggle = async (profiles, user) => {
        try {
            const { data } = await GetSimilarCar({
                headers: {
                    Authorization: `Token ${profiles?.Authorization}`,
                }
            }, params.Productid);
            SetUserdata(data.results)
        } catch (error) {
            if (error?.response?.status === 500) {
                dispatch(StoreLanguage.setProps([{
                    Title: '500 server error',
                    SubTitle: '500 server error',
                    Styling: {
                        Icon: 'Error',
                    }
                }]));
            } else {
                let temp = []
                for (let er in error?.response?.data) {
                    temp.push({
                        Title: er,
                        SubTitle: Array.isArray(error?.response?.data[er]) ? error?.response?.data[er][0] : error?.response?.data[er],
                        Styling: {
                            Icon: 'Error',
                        }
                    })
                }
                dispatch(StoreLanguage.setProps(temp))
            }
        }
    }
    const copyLinkToClipboard = (link) => {
        navigator.clipboard.writeText(link)
            .then(() => {
                dispatch(StoreLanguage.setProps([{
                    Title: 'Link copied',
                    SubTitle: 'Link copied to clipboard',
                    Styling: {
                        Icon: 'Success',
                    }
                }]))
                setShowIcons(false);
            })
            .catch((error) => {
                console.error('Unable to copy link to clipboard', error);
            });
    };
    const edit = (prop) => {
        dispatch(SetAddNewPost(true))
        dispatch(PatchPostedcar(prop))
    }
    // const [PrimaryFeatures, setPrimaryFeatures] = useState([Arrayfields.owner_info, Arrayfields.type, Arrayfields.km_driven, Arrayfields.transmission_type])
    // const PrimaryFeatures = ['UP-16', '1st Owner', 'Diesel', '86,395 km', 'AUTOMATIC']
    const Moretext = () => {

        Setshowmore(!showmore)
    }
    const mapingByTags = (APIData, Localdata) => {
        return APIData.map(a => {
            const matchingData = Localdata.find(b => b.tag === a.tag);
            if (matchingData) {
                return {
                    title: a.title,
                    ...matchingData
                };
            } else {
                return null;
            }
        }).filter(item => item !== null);
    }
    const GettingBanners = async (profiles) => {
        try {
            const { data } = await GetCar(profiles?.Authorization && {
                headers: {
                    Authorization: `Token ${profiles?.Authorization}`,
                }
            }, params.Productid);

            let DumOverview = [
                'year', 'history', 'varient', 'km_driven', 'registered_in',
                'engine_displacement', 'last_service_km', 'last_service_on', 'fuel', 'mileage'
            ].map(tag => ({
                tag,
                text: data.overview[tag]
            }));
            DumOverview = [...DumOverview, { tag: 'owner', text: data?.owner?.first_name ? data.owner.first_name : 'Guest Name' }]
            const DumFeature = [
                'power_steering', 'power_windows_front', 'air_conditioner',
                'fog_lights_front', 'alloy_wheels', 'multi_function_steering_wheel'
            ].map(tag => ({
                tag,
                text: data.features[tag]
            }));

            const DumSpecification = [
                'max_power', 'transmission_type', 'boot_space_in_ltr',
                'drive_type', 'front_suspension', 'rear_suspension',
                'kerb_weight', 'gross_weight'
            ].map(tag => ({
                tag,
                text: data.specification[tag]
            }));

            // const Overview = mapingByTags(specials.length ? specials : prop.data.inetialPage, DumOverview);
            // const Features = mapingByTags(inFeatures.length ? inFeatures : prop.data.Features, DumFeature);
            // const Specification = mapingByTags(SpecificationLocal.length ? SpecificationLocal : prop.data.Specification, DumSpecification);

            const Overview = mapingByTags(prop.data.inetialPage, DumOverview);
            const Features = mapingByTags(prop.data.Features, DumFeature);
            const Specification = mapingByTags(prop.data.Specification, DumSpecification);

            SetFeatures({
                Overview,
                Features,
                Specification
            });

            SetRawdata(data);

        } catch (error) {
            const temp = Object.entries(error?.response?.data).map(([Title, SubTitle]) => ({
                Title,
                SubTitle: SubTitle[0],
                Styling: {
                    Icon: 'Error',
                }
            }));

            dispatch(StoreLanguage.setProps(temp));
        }
    }

    // useEffect(() => {
    //     if (profiles?.Authorization && !Userdata.id) {
    //         GettingBanners(profiles)
    //     } else if (!Userdata.id) {
    //         GettingBanners(profiles)
    //     }
    // }, [profiles]);


    // we can remove later
    // useEffect(() => {
    //     if (profiles?.Authorization && !Userdata.id) {
    //         GettingBanners(profiles)
    //         Toggle(profiles)
    //     }
    // }, [profiles]);
    // UnCommit hti code when More Load commites is Implemented 
    // const GetRevie = async (profiles) => {
    //     try {
    //         const { data } = await ReviewOfVehicle(
    //             {
    //                 headers: {
    //                     Authorization: `Token ${profiles?.Authorization}`,
    //                 }
    //             }
    //         )
    //         SetReviewData(data)
    //     } catch (error) {

    //     }
    // }
    // useEffect(() => {
    //     if(profiles?.Authorization){
    //         GetRevie(profiles)
    //     }
    // }, [profiles]);
    const ChangeTabPosition = (props, tabs) => {
        SetMobiletabs(tabs.map(a => a.Title === props.Title && !props.Unable ? { Title: props.Title, Unable: true, mainTitle: props.mainTitle } : { Title: a.Title, Unable: false, mainTitle: a.mainTitle }))
        SetTabdataMobile(props.Title)
    }
    let ReminingData = useSelector(state => state.storeContent.ListofCars);
    const ChangeTab = (props) => {
        Settabs(tabs.map(a => a.Title === props.Title ? { Title: props.Title, Unable: true, mainTitle: props.mainTitle } : { Title: a.Title, Unable: false, mainTitle: a.mainTitle }))
        SetTabdata(props.Title)
    }
    if (!params) {
        params = { Productid: 1 }
    }
    // check
    // useEffect(() => {
    //     if (params.Productid) {
    //         GettingBanners(profiles)
    //     }
    // }, [params]);
    const [CarsData, SetcarsData] = useState(prop.data)
    useEffect(() => {
        SetcarsData(prop.data)
    }, [prop]);
    const WriteReview = () => {
        dispatch(SetRatting({
            ID: params.Productid,
            Status: true
        }))
    }
    const buttonContent = (
        <div className="w-[253px] h-12 rounded-[10px] flex items-center justify-center cursor-pointer" style={{ background: "linear-gradient(220.6deg, #ffbe41 60.41%, #ff9334 121.42%)" }}>
            <p className="text-xl font-bold text-center uppercase text-[#333] center_align">
                {productdata.Button}
            </p>
        </div>
    );
    const AddingtoComarizion = () => {
        if (!profiles?.Authorization) {
            dispatch(setProps([CarsData.PopUpData.ErrorMessage.NoticeUserToLogin]))
            return;
        }
        // dispatch(StoreLanguage.SetListofCars(
        //     [
        //         ...ReminingData,
        //         {
        //             id: Rawdata.id,
        //         }]
        // ))
        // localStorage.setItem(`Comparison`, JSON.stringify([
        //     ...ReminingData,
        //     {
        //         id: Rawdata.id,
        //     }]));
        // dispatch(StoreLanguage.setProps(
        //     [CarsData.PopUpData.SuccessMessage.Coparision]
        // ))
    }

    const isMobileDevice = () => {
        return /Mobi|Android/i.test(navigator.userAgent);
    }

    const showPhoneNumber = (prop) => {
        // dispatch(setProps([{
        //     Title: 'User Phone Number',
        //     SubTitle: `Posted user phone number: ${prop}`,
        //     Styling: {
        //         Icon: 'Success',
        //     }
        // }]));
        setpopUpData(prop)
    }

    const callPhoneNumber = (prop) => {
        window.location.href = `tel:${prop}`;
    }

    const handleContactClick = (prop) => {
        if (!profiles?.Authorization) {
            // user is not authorized'
            dispatch(setProps([{
                Title: 'Please Login',
                SubTitle: 'please login to your account',
                Styling: {
                    Icon: 'Error',
                }
            }]));
        } else {
            if (isMobileDevice()) {
                showPhoneNumber({ ...prop, Ismobile: true });
            } else {
                showPhoneNumber(prop.owner);
            }
        }
    }
    const showCamara = (slected) => {
        setHideReasion(!HideReasion)
        if (!slected.name) return
        if (slected.name === 'cancel') {
            setSelectedReasion('Remove Listing');
            return
        }
        if (slected.tag === 'notforsale') {
            setSelectedReasion(slected.name)
            settagData(slected.tag)
            SubmitData(slected.tag)
            // setReasionStatus(true)
        } else {
            setSelectedReasion(slected.name)
            settagData(slected.tag)
            setReasionStatus(true)
        }
    }

    const currentUrl = window.location.href;
    const PopUpdata = async (propdata) => {
        handleContactClick(propdata)
        // try {
        //     const { data } = await GetbackRequest({
        //         headers: {
        //             Authorization: `Token ${profiles?.Authorization}`,
        //         }
        //     }, profiles?.id)
        //     dispatch(setProps([{
        //         Title: 'Team Will Contact',
        //         SubTitle: 'Team will contact user via email or phone promptly.',
        //         Styling: {
        //             Icon: 'Success',
        //         }
        //     }]));
        // } catch (error) {
        //     let temp = []
        //     for (let er in error?.response?.data) {
        //         temp.push({
        //             Title: er,
        //             SubTitle: Array.isArray(error?.response?.data[er]) ? error?.response?.data[er][0] : error?.response?.data[er],
        //             Styling: {
        //                 Icon: 'Error',
        //             }
        //         })
        //     }
        //     dispatch(StoreLanguage.setProps(temp))
        // }
    };
    const close = async (popup, number) => {
        if (popup === 'close') {
            settagData('')
            setstoreReasion('')
            setSelectedReasion('Remove Listing')
            setReasionStatus(false)
            return
        }
        if (popup) {
            callPhoneNumber(number);
            setpopUpData({})
        } else {
            setpopUpData({})
        }
        setReasionStatus(false)
        // if (ReasionStatus) {
        //     try {
        //         await ChnageStatus({
        //             headers: {
        //                 Authorization: `Token ${profiles?.Authorization}`,
        //             }
        //         }, {
        //             car_vehicle_obj: params.Productid,
        //             status: tagData,
        //             status_update_reason: storeReasion
        //         })
        //         setReasionStatus(false)
        //     } catch (error) {
        //         let temp = []
        //         for (let er in error?.response?.data) {
        //             temp.push({
        //                 Title: er,
        //                 SubTitle: Array.isArray(error?.response?.data[er]) ? error?.response?.data[er][0] : error?.response?.data[er],
        //                 Styling: {
        //                     Icon: 'Error',
        //                 }
        //             })
        //         }
        //         dispatch(StoreLanguage.setProps(temp))
        //     }
        // }
    }
    const Rinputdata = (props) => {
        setstoreReasion(props.UserEnterData)
    }
    const [showIcons, setShowIcons] = useState(false);
    const shareButtonRef = useRef(null);
    const shareLink = () => {
        setShowIcons(!showIcons);
    };

    const handleOutsideClick = (event) => {
        if (shareButtonRef.current && !shareButtonRef.current.contains(event.target)) {
            setShowIcons(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const SubmitData = async (prop) => {
        try {
            await ChnageStatus({
                headers: {
                    Authorization: `Token ${profiles?.Authorization}`,
                }
            }, {
                car_vehicle_obj: params.Productid,
                status: prop ? prop : tagData,
                status_update_reason: storeReasion
            })
            GettingBanners(profiles)
        } catch (error) {
            if (error?.response?.status === 500) {
                dispatch(StoreLanguage.setProps([{
                    Title: '500 server error',
                    SubTitle: '500 server error',
                    Styling: {
                        Icon: 'Error',
                    }
                }]));
            } else {
                let temp = []
                for (let er in error?.response?.data) {
                    temp.push({
                        Title: er,
                        SubTitle: Array.isArray(error?.response?.data[er]) ? error?.response?.data[er][0] : error?.response?.data[er],
                        Styling: {
                            Icon: 'Error',
                        }
                    })
                }
                dispatch(StoreLanguage.setProps(temp))
            }
        }
    }
    return (
        <>
            {popUpData?.Authorization && <div className='w-full min-h-full h-auto z-[12] bg_blur fixed top-0'>
                <div className='w-full h-[100vh] flex justify-center items-center'>
                    <div className=' bg-[#3B3F49] w-full md:w-[50%] lg:w-[35%] rounded-lg'>
                        <div className='p-4'>
                            <div className='w-full flex justify-end'>
                                <div className="w-[30px] h-[30px] rounded-full text-xl cursor-pointer text-white bg-[#62656D]" onClick={() => close()}>
                                    <div className="flex items-center justify-center">x</div>
                                </div>
                            </div>
                            <div className='px-4'>
                                <div>
                                    <div className='flex items-start flex-col text-white py-5'>
                                        <div className='text-lg pb-1'>
                                            Thank you for your interest. You can contact {popUpData?.first_name} on this number {popUpData?.phone_number || popUpData?.email} for more details.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`w-full flex items-center pb-2 ${popUpData.Ismobile ? 'justify-between' : 'justify-center'}`}>
                                <div className="w-[90px] h-8 rounded-[5px] cursor-pointer flex justify-center items-center" onClick={() => close()} style={{ background: "linear-gradient(220.6deg, #ffbe41 60.41%, #ff9334 121.42%)" }}>
                                    <p className="text-md font-bold text-center uppercase text-[rgb(51,51,51)] center_aline">
                                        Close
                                    </p>
                                </div>
                                {(popUpData.Ismobile && popUpData?.phone_number) && <div className="w-[90px] h-8 rounded-[5px] cursor-pointer flex justify-center items-center" onClick={() => close(popUpData.Ismobile, popUpData?.phone_number)} style={{ background: "linear-gradient(220.6deg, #ffbe41 60.41%, #ff9334 121.42%)" }}>
                                    <p className="text-md font-bold text-center uppercase text-[rgb(51,51,51)] center_aline">
                                        Call
                                    </p>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            {ReasionStatus && <div className='w-full min-h-full h-auto z-[12] bg_blur fixed top-0'>
                <div className='w-full h-[100vh] flex justify-center items-center'>
                    <div className=' bg-[#3B3F49] w-full md:w-[50%] lg:w-[35%] rounded-lg'>
                        <div className='p-4'>
                            <div className='w-full flex justify-end'>
                                <div className="w-[30px] h-[30px] rounded-full text-xl cursor-pointer text-white bg-[#62656D]" onClick={() => close('close')}>
                                    <div className="flex items-center justify-center">x</div>
                                </div>
                            </div>
                            <div className='px-4'>
                                <div className="flex items-center justify-center pb-[20px]">
                                    <div className="w-[80%]">
                                        <Rinput data={{ Holder: `Update buyer's details for self reference`, placeHolder: 'Note', Type: 'text', tags: 'vehicle_license_plate' }} Rinputdata={Rinputdata} />
                                    </div>
                                </div>
                            </div>
                            <div className={`w-full flex items-center pb-2 justify-center`}>
                                <div className="w-[90px] h-8 rounded-[5px] cursor-pointer flex justify-center items-center" onClick={() => close()} style={{ background: "linear-gradient(220.6deg, #ffbe41 60.41%, #ff9334 121.42%)" }}>
                                    <p className="text-md font-bold text-center uppercase text-[rgb(51,51,51)] center_aline" onClick={() => SubmitData()}>
                                        Submit
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            {(Rawdata && Rawdata.id) && <div className='min-h-full h-auto hidden md:block lg:block Def-font'>
                <ImgSlider data={Rawdata.image_objs.length ? Rawdata.image_objs.map(a => ({ 'image_url': a.image_url })) : [{ 'image_url': DefaultImage }]} />
                <div className='min-h-full h-auto w-full py-[50px] flex justify-center'>
                    <div className="w-[90%] h-auto  rounded-[30px] bg-[#ffffff26] border border-[#72787C]" style={{ boxShadow: "0px 2px 34px 0 rgba(0,0,0,0.18)" }}>
                        <div className='p-[30px]'>
                            <div className='w-full flex'>
                                <p className="text-[34px] font-medium text-left text-white w-1/2">{Rawdata.name}</p>
                                <div className='w-1/2 flex items-center justify-end'>
                                    {/* <div className='text-white pr-3'>Remove Listing: </div>  */}
                                    {(profiles?.id === Rawdata?.owner.id) && <div className='flex'>
                                        <div className='mr-5 w-[145px] relative'>
                                            <div className='p-2 px-4 w-full text-white cursor-pointer  border-2 border-[#AFB5BB] rounded-md' onClick={() => showCamara({ name: 'cancel' })}>{Rawdata.status !== "For Sale" ? Rawdata?.status : SelectedReasion}</div>
                                            {HideReasion && <div className='w-full z-9 rounded-md bg-[#3b3f49] border-2 border-[#ffffff26] absolute'>
                                                <div className='p-2 w-full text-white cursor-pointer border-b-2 border-[#AFB5BB]' onClick={() => showCamara({ name: 'Sold', tag: 'sold' })}>Sold</div>
                                                <div className='p-2 w-full text-white cursor-pointer' onClick={() => showCamara({ name: 'Not For Sale', tag: 'notforsale' })}>Not For Sale</div>
                                                {/* <div className='p-2 w-full text-white cursor-pointer' onClick={() => showCamara({ name: 'cancel' })}>Cancel</div> */}
                                            </div>}
                                        </div>
                                        <img src={editPen} className="h-[34px] w-[34px] mr-[20px] cursor-pointer" alt="logo" onClick={() => edit(Rawdata)} />
                                        <div className='relative'>
                                            <img src={share} onClick={() => shareLink()} className="h-[34px] w-[34px] cursor-pointer" alt="logo" />
                                            {showIcons && <div ref={shareButtonRef} className='flex flex-col absolute'>
                                                <div className='py-1 pt-2' title="Link copied">
                                                    <img src={copy} className='w-[32px] h-[32px] bg-white rounded-full cursor-pointer' onClick={() => copyLinkToClipboard(currentUrl)} />
                                                </div>
                                                <div className='py-1 pt-2' title="FaceBook">
                                                    <FacebookShareButton url={currentUrl}>
                                                        <div>
                                                            <FacebookIcon size={32} round={true} />
                                                        </div>
                                                    </FacebookShareButton>
                                                </div>
                                                {/* <div className='pb-1'>
                                                    <InstagramShareButton url={currentUrl}>
                                                        <div>
                                                            <InstagramIcon size={32} round={true} />
                                                        </div>
                                                    </InstagramShareButton>
                                                </div> */}
                                                <div className='pb-1' title="Email">
                                                    <EmailShareButton url={currentUrl} subject="Check out this link">
                                                        <div>
                                                            <EmailIcon size={32} round={true} />
                                                        </div>
                                                    </EmailShareButton>
                                                </div>
                                                <div className='pb-1' title="X">
                                                    <TwitterShareButton url={currentUrl}>
                                                        <div>
                                                            <XIcon size={32} round={true} />
                                                        </div>
                                                    </TwitterShareButton>
                                                </div>
                                                <div className='pb-1' title="WhatsApp">
                                                    <WhatsappShareButton url={currentUrl} title="101 Mile">
                                                        <div>
                                                            <WhatsappIcon size={32} round={true} />
                                                        </div>
                                                    </WhatsappShareButton>
                                                </div>
                                                {/* <div className='pb-1'>
                                                    <RedditShareButton url="https://example.com" title="Title">
                                                        <div>
                                                            <RedditIcon size={32} round={true} />
                                                        </div>
                                                    </RedditShareButton>
                                                </div> */}
                                            </div>}
                                        </div>
                                    </div>}
                                    {profiles?.Authorization ? (
                                        profiles?.id !== Rawdata?.owner.id && (
                                            <div className="w-[253px] h-12">
                                                <NavLink to={`/product-comparison/${Rawdata.id}`} activeClassName="active-link">
                                                    <div className="w-[253px] h-12 rounded-[10px] flex items-center justify-center cursor-pointer" style={{ background: "linear-gradient(220.6deg, #ffbe41 60.41%, #ff9334 121.42%)" }}>
                                                        <p className="text-xl font-bold text-center uppercase text-[rgb(51,51,51)] center_aline">
                                                            {productdata.Button}
                                                        </p>
                                                    </div>
                                                </NavLink>
                                            </div>
                                        )
                                    ) : (
                                        profiles?.id !== Rawdata?.owner.id && (
                                            <div className="w-[253px] h-12">
                                                <div className="w-[253px] h-12 rounded-[10px] flex items-center justify-center cursor-pointer" style={{ background: "linear-gradient(220.6deg, #ffbe41 60.41%, #ff9334 121.42%)" }} onClick={() => AddingtoComarizion()}>
                                                    <p className="text-xl font-bold text-center uppercase text-[rgb(51,51,51)] center_aline">
                                                        {productdata.Button}
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                            <div className='py-[30px] w-full flex items-center'>
                                <div className='w-1/2 flex items-center '>
                                    <div className="w-[161px] h-[29px] mr-[30px]">
                                        {Rawdata.status === 'For Sale' && <div className="w-[161px] h-[29px] rounded-[10px] bg-[#3cc676] flex items-center justify-center" >
                                            <p className="text-base font-medium text-center text-white flex items-center justify-center">
                                                {productdata.Status}
                                            </p>
                                        </div>}
                                        {Rawdata.status !== 'For Sale' && <div className='h-[29px] min-w-fit bg-[#FEE0E0] flex items-center justify-center rounded-md'>
                                            <p className="text-base font-medium text-center uppercase text-[#e95502] m-1 rounded-sm mx-2">
                                                {Rawdata.status}
                                            </p>
                                        </div>}
                                    </div>
                                    {/* <p className="text-[34px] text-left text-white">{`${Rawdata.price?.toLocaleString(currencies?.format, { style: 'currency', currency: currencies.key })}`}</p>                                     */}
                                    <p className="text-[34px] text-left text-white">{formatter.format(Rawdata.price)}</p>
                                </div>
                                <div className='w-1/2 flex justify-end'>
                                    <div className="w-[287px] h-[27px] flex justify-end">
                                        <img src={stars} className="h-[24px] w-[24px]" alt="logo" />
                                        <p className="text-base font-medium text-left text-white pl-[10px]">{Rawdata.rating?.average_rating}</p>
                                        <p className="text-base text-left text-[#9faab8] mx-[10px]">({Rawdata.rating?.num_reviews} Review)</p>
                                        {(profiles?.id !== Rawdata?.owner.id) && <div className="w-[106px] h-[27px] cursor-pointer" onClick={() => WriteReview()}>
                                            <div className="w-[106px] h-[27px] rounded-[5px] bg-[#aaa] flex items-center justify-center">
                                                <p className="text-sm font-medium text-left text-[#3a3f49]">
                                                    {productdata.WriteReview}
                                                </p>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                            {[Arrayfields.owner_info, Arrayfields.type, Arrayfields.km_driven, Arrayfields.transmission_type].filter(a => a).length > 0 && <div className='pb-[30px]'>
                                <div className="md:w-[607px] lg:w-[807px] h-[30px] flex items-center">
                                    {[Arrayfields.owner_info, Arrayfields.type, Arrayfields.km_driven, Arrayfields.transmission_type].map((a, index) => (
                                        a && <p key={index} className="text-2xl text-left text-white pr-[50px]">{a}</p>
                                    ))}
                                </div>
                            </div>}
                            <div className='pb-[30px]'>
                                {(Rawdata.description && !showmore) && <p className="w-full text-base text-left">
                                    <span className="w-full text-base text-left text-white">
                                        {Rawdata.description.length > 420 ? Rawdata.description.substr(0, 420) + '...' : Rawdata.description}
                                    </span>
                                    {Rawdata.description.length > 420 && <span className="w-full text-base font-medium text-left text-[#ffc727] cursor-pointer" onClick={() => Moretext()}>more</span>}
                                </p>}
                                {(Rawdata.description && showmore) && <p className="w-full text-base text-left">
                                    <span className="w-full text-base text-left text-white">
                                        {Rawdata.description}
                                    </span>
                                    {Rawdata.description.length > 420 && <span className="w-full text-base font-medium text-left text-[#ffc727] cursor-pointer" onClick={() => Moretext()}> Show Less</span>}
                                </p>}
                            </div>
                            <div>
                                <div>
                                    <div className="w-full h-[52px]">
                                        <div className='flex'>
                                            {tabs.map(a => (
                                                <div className='cursor-pointer' onClick={() => ChangeTab(a)}>
                                                    {
                                                        a.Unable && <div className='px-[10px] pt-[10px] pb-[5px] text-lg font-medium text-[#ffba40] bg-[#bebcbc5c] rounded-t-lg'>
                                                            {a.mainTitle}
                                                        </div>
                                                    }
                                                    {
                                                        <>
                                                            {
                                                                !a.Unable && <p className="px-[10px] pt-[10px] pb-[5px] text-lg font-medium text-white">{a.mainTitle}</p>
                                                            }
                                                        </>
                                                    }
                                                </div>

                                            ))}
                                        </div>
                                        <img src={line} className="w-full" alt="logo" />
                                    </div>
                                </div>
                                <div className='p-[30px]'>
                                    {Tabdata !== 'Features' && <div className="w-full h-[118px] grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5">
                                        {Features[Tabdata].map(a => (
                                            <div className="w-[220px] h-11">
                                                <p className="text-sm text-left text-white">{a.title}</p>
                                                <p className="text-lg font-medium text-left text-white capitalize">{a.text ? a.text : '--'}</p>
                                            </div>
                                        ))}
                                    </div>}
                                    {Tabdata === 'Features' && <div className="w-full h-[118px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
                                        {Tabdata === 'Features' && Features[Tabdata].map(a => (
                                            <div className="w-[250px] h-11 flex justify-between">
                                                <p className="text-sm text-left text-white">{a.title}</p>{a.text ?
                                                    (<img src={TickMark} className="w-[18px] h-[14px]" alt="logo" />) :
                                                    (<div className='w-[18px] h-[14px] text-red-500 text-[17px]'>X</div>)
                                                }
                                            </div>
                                        ))}
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {<div className='px-[5%] w-full'>
                    <div className='flex items-center w-full'>
                        <div className='flex w-1/2'>
                            <img src={logo} className="ring ring-offset-0 ring-blue-500 w-[74px] h-[74px] rounded-full mr-[30px]" alt="logo" />
                            <div className='pb-4'>
                                <p className="text-[28px] font-medium text-left text-white">{Rawdata?.owner.first_name ? Rawdata?.owner.first_name : 'Guest Role'}</p>
                                <div className='flex'>
                                    <img src={stars} className="h-[24px] w-[24px]" alt="logo" />
                                    <p className="text-base font-medium text-left text-white pl-[10px]">{Rawdata?.user_rating.average_rating}</p>
                                    <p className="text-base text-left text-[#9faab8] mx-[10px]">({Rawdata?.user_rating.num_reviews} Review)</p>
                                </div>
                            </div>
                        </div>
                        <div className="h-12 w-1/2 flex justify-end">
                            <div className="w-[190px] h-12 rounded-[10px] flex justify-center items-center cursor-pointer" style={{ background: "linear-gradient(220.6deg, #ffbe41 60.41%, #ff9334 121.42%)" }} onClick={() => PopUpdata(Rawdata)}>
                                <p className="text-xl font-bold text-center uppercase text-[#333]">
                                    {productdata.CallMeet}
                                </p>
                            </div>
                        </div>
                    </div>
                    {Userdata.length > 0 && <Cards data={Userdata} AllData={prop?.data?.CardData?.Cards} />}
                    <div>
                        {Rawdata.reviews.length > 0 &&
                            <>
                                <div className='py-[30px]'>
                                    <p className="text-[34px] font-medium text-left text-white">{productdata.userReview}</p>
                                </div>

                                <img src={line} className="w-full" alt="logo" />
                            </>
                        }
                        {Rawdata.reviews.map((review, index) => (
                            <div key={index}>
                                <div className='flex py-[16px]'>
                                    <img src={stars} className="h-[24px] w-[24px]" alt="logo" />
                                    <p className="text-base font-medium text-left text-white pl-[10px]">{review.overall_rating_average}</p>
                                </div>
                                <p className="text-xl font-medium text-left text-white pb-[15px]">
                                    {review.review_title}
                                </p>
                                <p className="text-xl text-left text-[#9faab8] pb-[15px]">
                                    {review.experience_details}
                                </p>
                                <p className="text-sm font-medium text-left text-[#9faab8] pb-[15px]">
                                    {`On ${review.created_on} | ${review.views} views`}
                                </p>
                                {Rawdata.reviews.length !== index + 1 && <img src={line} className="w-full" alt="logo" />}
                            </div>
                        ))}


                    </div>
                </div>}
                {CarsData.CardData.subbanner}
            </div>}
            {(Rawdata && Rawdata.id) && <div className='min-h-full h-auto sm:block md:hidden lg:hidden Def-font'>
                <MobileSlider data={Rawdata.image_objs.length ? Rawdata.image_objs.map(a => ({ 'image_url': a.image_url })) : [{ 'image_url': DefaultImage }]} />
                <div>
                    <div className='py-[20px] px-[5%]'>
                        <p className="text-xl font-medium text-left text-white pb-[11px]">{Rawdata.name}</p>
                        <div className='flex justify-between items-center'>
                            {/* <p className="text-sm font-medium text-left text-white">{`${Rawdata.price?.toLocaleString(currencies?.format, { style: 'currency', currency: currencies.key })}`}</p> */}
                            <p className="text-sm font-medium text-left text-white">{formatter.format(Rawdata.price)}</p>
                            <div className='w-[145px] relative'>
                                <div className='p-2 px-4 w-full text-white cursor-pointer  border-2 border-[#AFB5BB] rounded-md' onClick={() => showCamara({ name: 'cancel' })}>{Rawdata.status !== "For Sale" ? Rawdata?.status : SelectedReasion}</div>
                                {HideReasion && <div className='w-full z-9 rounded-md bg-[#3b3f49] border-2 border-[#ffffff26] absolute'>
                                    <div className='p-2 w-full text-white cursor-pointer border-b-2 border-[#AFB5BB]' onClick={() => showCamara({ name: 'Sold', tag: 'sold' })}>Sold</div>
                                    <div className='p-2 w-full text-white cursor-pointer' onClick={() => showCamara({ name: 'Not For Sale', tag: 'notforsale' })}>Not For Sale</div>
                                    {/* <div className='p-2 w-full text-white cursor-pointer' onClick={() => showCamara({ name: 'cancel' })}>Cancel</div> */}
                                </div>}
                            </div>
                        </div>
                        <div className='flex justify-between items-center pt-[11px]'>
                            <div className="w-[113px] h-[19px]">
                                <div className="w-[113px] h-[19px] rounded-[5px] bg-[#3cc676] flex items-center justify-center">
                                    <p className="text-[11px] font-medium text-center text-white">
                                        {productdata.Status}
                                    </p>
                                </div>
                            </div>
                            <div className='flex'>
                                <img src={stars} className="h-[18px] w-[18px]" alt="logo" />
                                <p className="text-sm font-medium text-left text-white pl-[10px]">{Rawdata.rating?.rate}</p>
                                <p className="text-sm text-left text-[#9faab8] mx-[10px]">{Rawdata.rating?.review ? Rawdata.rating?.review : 0} Review</p>
                            </div>
                        </div>
                    </div>
                    <div
                        className="h-[7px] bg-[#ffffff26]"
                        style={{ boxShadow: "0px 2px 34px 0 rgba(0,0,0,0.18)" }}
                    />
                    <div className='px-[5%]'>
                        <div>
                            <div className=''>
                                <div className='py-[10px]'>
                                    <div className='flex justify-between'>
                                        <p className="text-sm text-left text-white pb-3">Specifications</p>
                                    </div>
                                    {Rawdata.description && <p className="text-sm text-left">
                                        <span className="text-sm text-left text-[#aeb6bf] line-clamp-3">{Rawdata.description}</span>
                                        {/* <span className="text-sm font-medium text-left text-[#ffc727]">more</span> */}
                                    </p>}
                                </div>
                                <div
                                    className="h-0.5 bg-[#ffffff26]"
                                    style={{ boxShadow: "0px 2px 34px 0 rgba(0,0,0,0.18)" }}
                                />
                            </div>
                            {Mobiletabs.map(a => (
                                <div className='cursor-pointer' onClick={() => ChangeTabPosition(a, Mobiletabs)}>
                                    <div className='py-[10px]'>
                                        <div className='flex justify-between'>
                                            <p className="text-sm text-left text-white">{a.Title}</p>
                                            <img src={DownAerrow} className="w-[35px] h-[17px]" alt="logo" />
                                        </div>
                                        {a.Unable && <div className="w-full h-full grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5">
                                            {TabdataMobile !== 'Features' && Features[TabdataMobile].map(a => (
                                                <div className="h-11 my-[20px]">
                                                    <p className="text-sm text-left text-white">{a.title}</p>
                                                    <p className="text-lg font-medium text-left text-white">{a.text}</p>
                                                </div>
                                            ))}
                                            {TabdataMobile === 'Features' && <div className="pt-[20px] w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
                                                {TabdataMobile === 'Features' && Features[TabdataMobile].map(a => (
                                                    <div className="w-[250px] h-11 flex justify-between">
                                                        <p className="text-sm text-left text-white">{a.title}</p><img src={TickMark} className="w-[18px] h-[14px]" alt="logo" />
                                                    </div>
                                                ))}
                                            </div>}
                                        </div>}
                                    </div>
                                    <div
                                        className="h-0.5 bg-[#ffffff26]"
                                        style={{ boxShadow: '0px 2px 34px 0 rgba(0,0,0,0.18)' }}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flex items-center justify-center w-full py-5">
                        <div className="w-[253px] h-12">
                            {profiles?.Authorization ? (
                                <NavLink to={`/product-comparison/${Rawdata.id}`}>
                                    {buttonContent}
                                </NavLink>
                            ) : (
                                <div onClick={() => AddingtoComarizion()}>
                                    {buttonContent}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className='px-[5%] w-full'>
                    <div className='flex w-full pb-3'>
                        <img src={logo} className="ring ring-offset-0 ring-blue-500 w-[74px] h-[74px] rounded-full mr-[30px]" alt="logo" />
                        <div className='pb-4'>
                            <p className="text-[28px] font-medium text-left text-white">{Rawdata?.owner.first_name ? Rawdata?.owner.first_name : 'Guest Role'}</p>
                            <div className='flex'>
                                <img src={stars} className="h-[24px] w-[24px]" alt="logo" />
                                <p className="text-base font-medium text-left text-white pl-[10px]">{Rawdata?.user_rating.average_rating}</p>
                                <p className="text-base text-left text-[#9faab8] mx-[10px]">({Rawdata?.user_rating.num_reviews} Review)</p>
                            </div>
                        </div>
                    </div>
                    <div className="h-12 w-full flex justify-center">
                        <div className="w-[190px] h-12 rounded-[10px] flex justify-center items-center cursor-pointer" style={{ background: "linear-gradient(220.6deg, #ffbe41 60.41%, #ff9334 121.42%)" }} onClick={() => PopUpdata(Rawdata.owner)}>
                            <p className="text-xl font-bold text-center uppercase text-[#333]">
                                {productdata.CallMeet}
                            </p>
                        </div>
                    </div>
                    {Userdata.length > 0 && <Cards data={Userdata} AllData={prop.data.CardData.Cards} />}
                    <div>
                        {Rawdata.reviews.length > 0 &&
                            <>
                                <div className='py-[30px]'>
                                    <p className="text-[34px] font-medium text-left text-white">{productdata.userReview}</p>
                                </div>

                                <img src={line} className="w-full" alt="logo" />
                            </>
                        }
                        {Rawdata.reviews.map((review, index) => (
                            <div key={index}>
                                <div className='flex py-[16px]'>
                                    <img src={stars} className="h-[24px] w-[24px]" alt="logo" />
                                    <p className="text-base font-medium text-left text-white pl-[10px]">{review.overall_rating_average}</p>
                                </div>
                                <p className="text-xl font-medium text-left text-white pb-[15px]">
                                    {review.review_title}
                                </p>
                                <p className="text-xl text-left text-[#9faab8] pb-[15px]">
                                    {review.experience_details}
                                </p>
                                <p className="text-sm font-medium text-left text-[#9faab8] pb-[15px]">
                                    {`On ${review.created_on} | ${review.views} views`}
                                </p>
                                {Rawdata.reviews.length !== index + 1 && <img src={line} className="w-full" alt="logo" />}
                            </div>
                        ))}
                    </div>
                </div>
                {CarsData.CardData.subbanner}
            </div>}
        </>
    );
}

export default Appcomparing;
